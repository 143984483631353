import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { Formik, Form } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import logo from "../../assets/images/logo.svg";
import theme from "../../theme";
import FormikInput from "../../components/FormikInput/FormikInput";
import authService from "../../services/AuthServices";
import { registerValidationSchema } from "../../Validations/RegisterValidation";
import OtpInput from "../../components/OtpInput";
import otpValidationSchema from "../../Validations/otpValidation";

function Register() {
  const navigate = useNavigate();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [, setUuid] = useState("");
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const initialValues = {
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const otpInitialValues = {
    otp: ["", "", "", "", "", ""],
  };

  const handleRegister = async (values, { setSubmitting, setFieldError }) => {
    setIsLoading(true);
    try {
      const sanitizedValues = {
        ...values,
        email: values.email.trim(),
      };
      const response = await authService.register(sanitizedValues);
      setUuid(response.uuid);
      setEmail(sanitizedValues.email);
      setPassword(sanitizedValues.password);
      setName(sanitizedValues.fullName);
      setShowOtpPopup(true);
    } catch (error) {
      console.error(
        "Registration error:",
        error.response?.data || error.message
      );
      setFieldError(
        "email",
        error.response?.data?.message || "Registration failed."
      );
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  const handleOtpSubmit = async (values, { setSubmitting, setFieldError }) => {
    const otpCode = values.otp.join("").trim();
    setIsLoading(true);
    try {
      console.log("Sending OTP verification payload:", {
        email,
        otp: otpCode,
        password,
        name,
      });

      await authService.registerVerifyOtp({
        email,
        otp: otpCode,
        password,
        name,
      });

      setSuccessMessage("Account Creation Success. Please login now!");
      setTimeout(() => {
        setShowOtpPopup(false);
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.error(
        "OTP verification error:",
        error.response?.data || error.message
      );
      setFieldError("otp", "Invalid OTP. Please try again.");
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.background.dark,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={registerValidationSchema}
        onSubmit={handleRegister}
      >
        {({ handleSubmit }) => (
          <Form>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                maxWidth: "1100px",
                minHeight: "75vh",
                bgcolor: "white",
                boxShadow: 3,
                flexDirection: matchDownSm ? "column-reverse" : "row",
                borderRadius: 2,
                overflow: "hidden",
                alignItems: "stretch",
                justifyContent: "stretch",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  padding: matchDownSm ? 6 : 8,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ mb: 3, fontWeight: "bold" }}
                >
                  Sign Up
                </Typography>

                <FormikInput
                  label="Full Name"
                  name="fullName"
                  placeholder="Enter your full name"
                />
                <FormikInput
                  label="Email"
                  name="email"
                  placeholder="Enter your email"
                />
                <FormikInput
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                />
                <FormikInput
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm your password"
                />

                <LoadingButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2, color: "white" }}
                  size="large"
                  loading={isLoading}
                  onClick={handleSubmit}
                >
                  Sign Up
                </LoadingButton>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  height: "100%",
                  minHeight: "75vh",
                  background: theme.palette.background.light,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: matchDownSm ? 6 : 8,
                  color: "white",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    position: "relative",
                    flex: "0 0 auto",
                    marginBottom: 0,
                    width: "350px", // Set a fixed width for the box
                    height: "auto",
                  }}
                >
                  <img
                    src={logo}
                    alt="logo"
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "block",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "50%", // Covers the bottom 50% of the image
                      background: "rgba(255, 255, 255, 0.1)", // Add a light blur-like overlay
                      backdropFilter: "blur(10px)", // Apply blur effect
                      WebkitBackdropFilter: "blur(10px)", // Safari compatibility
                      zIndex: 1, // Ensure it's above the image
                    }}
                  />
                </Box>

                <Typography
                  sx={{ fontSize: 17, color: "#626262", pt: 10 }}
                  align="center"
                >
                  A virtual AI agent at a fraction of cost to understand legacy
                  codebases!
                </Typography>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>

      <Dialog
        open={showOtpPopup}
        onClose={() => setShowOtpPopup(false)}
        sx={{
          "& .MuiDialog-paper": {
            width: "400px",
            maxWidth: "90%",
            padding: "20px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
          Verify OTP
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          {successMessage ? (
            <Typography color="success" sx={{ mb: 2 }}>
              {successMessage}
            </Typography>
          ) : (
            <Formik
              initialValues={otpInitialValues}
              validationSchema={otpValidationSchema}
              onSubmit={handleOtpSubmit}
            >
              {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                <Form>
                  <Typography sx={{ mb: 1 }}>
                    Please enter the OTP sent to your email.
                  </Typography>
                  <OtpInput
                    name="otp"
                    otp={values.otp}
                    setOtp={(newOtp) => setFieldValue("otp", newOtp)}
                  />
                  {errors.otp && touched.otp && (
                    <Typography color="error" sx={{ mt: 1 }}>
                      {errors.otp}
                    </Typography>
                  )}
                  <LoadingButton
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleSubmit}
                    loading={isLoading}
                  >
                    Verify OTP
                  </LoadingButton>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Register;
