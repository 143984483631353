import React, { useState } from "react";
import { useField } from "formik";
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const FormikInput = ({ label, type = "text", placeholder, ...props }) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  const isPasswordField = type === "password";

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Box>
      <Typography variant="body1" sx={{ mb: 0.5 }}>
        {label}
      </Typography>

      <TextField
        {...field}
        {...props}
        fullWidth
        margin="none"
        type={isPasswordField && showPassword ? "text" : type}
        placeholder={placeholder}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        InputProps={{
          endAdornment: isPasswordField && (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {showPassword ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <VisibilityOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ mb: 1.5 }}
      />
    </Box>
  );
};

export default FormikInput;
