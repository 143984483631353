import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Chip,
  Box,
} from "@mui/material";
import axiosServices from "../utils/axiosServices";
import Loader from "../components/loader";

function AddProject({ onSuccess, onFailure }) {
  const [formData, setFormData] = useState({
    projectName: "",
    description: "",
    file: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    projectName: false,
    description: false,
    file: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.name.toLowerCase().endsWith(".zip")) {
      setFormData({
        ...formData,
        file,
      });
    } else {
      setFormData({
        ...formData,
        file: null,
      });
    }
  };

  const validateForm = () => {
    const newErrors = {
      projectName: formData.projectName === "",
      description: formData.description === "",
      file: formData.file === null,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(Boolean);
  };

  const createProject = async () => {
    setIsLoading(true);
    let input = new FormData();
    input.append("file", formData.file);
    input.append("projectName", formData.projectName);
    input.append("description", formData.description);
    console.log("input", input);
    try {
      const response = await axiosServices.post("/projects", input, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Log the response for debugging
      console.log("API Response:", response);

      // Updated condition to handle success based on `message` field
      if (response.status >= 200 && response.status < 300) {
        if (response.data?.message === "Project created successfully") {
          console.log("Project creation successful");

          onSuccess();
        } else {
          console.error("Unexpected API response:", response.data);
          onFailure();
        }
      } else {
        console.error("API returned unexpected status:", response.status);
        onFailure();
      }
    } catch (error) {
      console.error("Error in createProject:", error);
      onFailure();
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      createProject();
    }
  };

  return (
    <>
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <TextField
            label="Project Name"
            name="projectName"
            fullWidth
            margin="normal"
            value={formData.projectName}
            onChange={handleChange}
            error={errors.projectName}
            helperText={errors.projectName && "Project Name is required"}
          />
          <TextField
            label="Description"
            name="description"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={formData.description}
            onChange={handleChange}
            error={errors.description}
            helperText={errors.description && "Description is required"}
          />
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            {!formData.file ? (
              <Button
                variant="contained"
                component="label"
                fullWidth
                margin="normal"
                sx={{ mt: 1 }}
              >
                Upload ZIP File
                <input
                  type="file"
                  hidden
                  accept=".zip"
                  onChange={handleFileChange}
                />
              </Button>
            ) : (
              <Chip
                label={formData.file.name}
                onDelete={() => setFormData({ ...formData, file: null })}
                color="primary"
                sx={{ mt: 2 }}
              />
            )}
          </Box>
          {errors.file && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              Please upload a ZIP file
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </form>
      </Container>
      <Loader open={isLoading} />
    </>
  );
}

export default AddProject;
