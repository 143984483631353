import { Container, Typography, Button } from "@mui/material";

function Support() {
  return (
    <Container>
      <Typography variant="h6" gutterBottom>
        Support
      </Typography>
      <Typography variant="body1" paragraph>
        Need assistance or want to view your support tickets? Please follow the instructions below:
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Instructions:</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        1. Click the button below to access our support portal.
      </Typography>
      <Typography variant="body1" paragraph>
        2. Create a Zoho account using the same email address that you use for this app.
      </Typography>
      <Typography variant="body1" paragraph>
        3. Use your Zoho account credentials to log in to the support portal.
      </Typography>
      <Typography variant="body1" paragraph>
        4. Once logged in, you can create new support tickets or view and manage your existing ones.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        href="https://harmonyengine.zohodesk.in/portal/en/home"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginTop: "16px" }}
      >
        Go to Support Portal
      </Button>
    </Container>
  );
}

export default Support;