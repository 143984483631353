import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const ReusableLoadingButton = ({
  children,
  loading,
  onClick,
  type = "button",
  variant = "contained",
  color = "primary",
  size = "large",
  fullWidth = true,
  sx = {},
  loadingIndicator = "Loading...",
  ...props
}) => {
  return (
    <LoadingButton
      variant={variant}
      color={color}
      size={size}
      fullWidth={fullWidth}
      type={type}
      onClick={onClick}
      loading={loading}
      loadingIndicator={loadingIndicator}
      sx={{ mt: 2, ...sx }}
      {...props}
    >
      {children}
    </LoadingButton>
  );
};

export default ReusableLoadingButton;
