// Loader.js
import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

const Loader = ({ open }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
      open={open}
    >
      <CircularProgress
        color="primary"
        size={50}
        thickness={3}
        sx={{
          animation: "spin 1.4s infinite linear",
        }}
      />
      <Typography
        variant="h6"
        sx={{ textTransform: "uppercase", letterSpacing: 1 }}
      >
        Loading...
      </Typography>
    </Backdrop>
  );
};

export default Loader;
