import React from "react";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const { user, logout, setUser } = useAuth();

  const handleLogout = () => {
    setUser(null);
    logout();
    navigate("/login");
    window.location.reload();
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: "transparent", // Ensure no background is applied
        boxShadow: "none", // Remove shadow
        color: "#000", // Set text color
      }}
    >
      <Toolbar
        sx={{
          padding: 0, // Remove default padding
        }}
      >
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
            color: "#525252",
            fontSize: "18px",
          }}
        >
          Welcome{" "}
          <span
            style={{ fontWeight: "bold", color: "#525252", fontSize: "18px" }}
          >
            {user?.user?.name || "Guest"}
          </span>
          !
        </Typography>
        <IconButton
          onClick={handleLogout}
          sx={{
            pr: 1.8,
          }}
        >
          <Logout />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
