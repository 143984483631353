import React from "react";
import { Box, TextField } from "@mui/material";
import { Field } from "formik";

const OtpInput = ({ name, digitCount = 6 }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Field name={name}>
        {({ field, form }) => (
          <>
            {Array.from({ length: digitCount }).map((_, index) => (
              <TextField
                key={index}
                id={`otp-input-${index}`}
                type="text"
                inputProps={{ maxLength: 1 }}
                value={field.value[index] || ""}
                onChange={(e) => {
                  const newOtp = [...field.value];
                  newOtp[index] = e.target.value.replace(/\D/g, "");
                  form.setFieldValue(name, newOtp);

                  if (e.target.value && index < digitCount - 1) {
                    const nextInput = document.getElementById(
                      `otp-input-${index + 1}`
                    );
                    nextInput?.focus();
                  }
                }}
                sx={{ width: "15%", textAlign: "center" }}
              />
            ))}
          </>
        )}
      </Field>
    </Box>
  );
};

export default OtpInput;
