import axiosServices from "../utils/axiosServices";

// const API_URL = "/auth/login";

const authService = {
  login: async (payload) => {
    try {
      console.log("Register Payload:", payload);
      const response = await axiosServices.post("auth/login", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  sendOtp: async (email) => {
    try {
      const response = await axiosServices.post("auth/send-otp", { email });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  verifyOtp: async (email, otp) => {
    try {
      const response = await axiosServices.post("auth/verify-otp", {
        email,
        otp,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error in verifyOtp:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  resetPassword: async (email, newPassword) => {
    try {
      const response = await axiosServices.post("auth/reset-password", {
        email,
        newPassword,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  register: async (payload) => {
    try {
      const response = await axiosServices.post("users/users", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error in register:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  registerVerifyOtp: async ({ name, email, otp, password }) => {
    try {
      const response = await axiosServices.post(
        "/users/create-user",
        { name, email, otp, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error in verifyOtp:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
};

export default authService;
