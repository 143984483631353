import * as Yup from "yup";
import ERROR_MESSAGES from "../constants/errorMessages";

export const registerValidationSchema = Yup.object({
  fullName: Yup.string()
    .matches(/^[a-zA-Z ]{3,}$/, ERROR_MESSAGES.INVALID_NAME)
    .required(ERROR_MESSAGES.REQUIRED),
  email: Yup.string()
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .required(ERROR_MESSAGES.REQUIRED),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      ERROR_MESSAGES.PASSWORD_WEAK
    )
    .required(ERROR_MESSAGES.REQUIRED),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], ERROR_MESSAGES.PASSWORD_MISMATCH)
    .required(ERROR_MESSAGES.REQUIRED),
});
