const ERROR_MESSAGES = {
  REQUIRED: "This field is required",
  INVALID_NAME: "Invalid name format",
  INVALID_EMAIL: "Please enter a valid email address.",
  PASSWORD_WEAK:
    "Password must be at least 8 characters, include one uppercase letter, one lowercase letter, one number, and one special character.",
  PASSWORD_MISMATCH: "Passwords must match",
};

export default ERROR_MESSAGES;
