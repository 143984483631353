import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const axiosServices = axios.create({
  baseURL: API_BASE_URL,
});

axiosServices.interceptors.request.use(
  (config) => {
    const storedUser = localStorage.getItem("user");
    let token;

    if (storedUser) {
      const userObject = JSON.parse(storedUser);
      token = userObject.token;
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      console.warn("Token not found. Authorization header not set.");
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const windowUrl = window.location.href;
      if (error.config.url !== "/auth/login") {
        const isLoginPage = windowUrl.includes("/login");
        localStorage.clear();
        if (!isLoginPage) {
          window.location.href = "/login";
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosServices;
