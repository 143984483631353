import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const ReusableAccordion = ({ title, children, onClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (event, expanded) => {
    setIsExpanded(expanded);
  };

  return (
    <Accordion
      onChange={handleChange}
      sx={{
        marginBottom: "10px",
        backgroundColor: "#F8F8F8",
        boxShadow: "none", // Remove shadow
        "&:before": {
          display: "none", // Remove default bottom border line
        },
      }}
      onClick={onClick}
    >
      <AccordionSummary
        expandIcon={isExpanded ? <RemoveIcon /> : <AddIcon />}
        sx={{
          borderBottom: "none", // Ensure no border-bottom for the summary
        }}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: "none", // Ensure no border-top for details
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default ReusableAccordion;
