import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import axiosServices from "../../utils/axiosServices";
import ReusableAccordion from "../../components/ReusableAccordion/ReusableAccordion";

const PinnedTopics = ({ projectId, userEmail, fetchPinnedTopics }) => {
  const [pinnedTopics, setPinnedTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noDataPinnedMessage, setNoDataPinnedMessage] = useState("");
  const [error, setError] = useState(null);

  const fetchPinnedTopicsData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      setNoDataPinnedMessage("");
      console.log("Fetching pinned topics data...");
      const response = await axiosServices.get("/pin", {
        params: {
          project_id: projectId,
          email: userEmail,
        },
      });

      console.log("Fetched pinned topics data:", response.data);

      if (response.data.message === "No pinned topics here") {
        setNoDataPinnedMessage("No pinned topics available.");
        setPinnedTopics([]);
      } else {
        setPinnedTopics(response.data);
        console.log("Updated pinned topics state:", response.data);
      }
    } catch (error) {
      console.error(
        "Error fetching pinned topics:",
        error.response?.data || error.message
      );
      setError(
        error.response?.data?.error ||
          "Failed to fetch pinned topics. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  }, [projectId, userEmail]);

  useEffect(() => {
    console.log("PinnedTopics component mounted");
    fetchPinnedTopicsData();
  }, [fetchPinnedTopicsData]);

  return (
    <Box>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : noDataPinnedMessage ? (
        <Typography color="textSecondary">{noDataPinnedMessage}</Typography>
      ) : (
        pinnedTopics.map((topic) => (
          <ReusableAccordion key={topic.id} title={topic.topic_name}>
            <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
              {topic.pin_content}
            </Typography>
          </ReusableAccordion>
        ))
      )}
    </Box>
  );
};

export default PinnedTopics;
