import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Breadcrumbs,
  Link,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLocation, useNavigate } from "react-router-dom";
import ReusableAccordion from "../../components/ReusableAccordion/ReusableAccordion";
import axiosServices from "../../utils/axiosServices";
import { useAuth } from "../../context/AuthContext";
import PushPinIconImg from "../../assets/images/pinIcon.svg";
import vector from "../../assets/images/Vector.svg";
import ExecutiveSummary from "./ExecutiveSummary";
import ChatBot from "./ChatBot";
import PinnedTopics from "./PinnedTopics";

const Chat = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const project = location.state?.project;
  const projectId = project?.project_id;
  const userEmail = user?.user?.email;

  const [, setPinnedTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [mermaidCode, setMermaidCode] = useState("");
  const [noDataMessage, setNoDataMessage] = useState("");
  const [, setNoDataPinnedMessage] = useState("");
  const [, setError] = useState(null);

  useEffect(() => {
    setLoading(false);
  }, []);

  const fetchMermaidCode = useCallback(async () => {
    try {
      setIsLoading(true);
      setNoDataMessage("");
      const response = await axiosServices.get(`/diagram`, {
        params: {
          project_id: projectId,
          email: userEmail,
        },
      });
      if (!response.data) {
        setMermaidCode({});
        setNoDataMessage(" ");
        return;
      }
      const processedData = JSON.parse(response.data);

      if (processedData) {
        setMermaidCode(processedData);
      } else {
        setMermaidCode({});
        setNoDataMessage("Error: Mermaid code not available.");
      }
    } catch (error) {
      console.error("Error fetching mermaid code:", error);
      setMermaidCode({});
      setNoDataMessage("Error: Failed to fetch mermaid code.");
    } finally {
      setIsLoading(false);
    }
  }, [projectId, userEmail]);

  useEffect(() => {
    if (projectId && userEmail) {
      fetchMermaidCode();
    }
  }, [fetchMermaidCode, projectId, userEmail]);

  const handleAccordionClick = (title, mermaidCode) => {
    navigate(`/mermaid/${encodeURIComponent(title)}`, {
      state: { mermaidCode },
    });
  };

  const fetchPinnedTopics = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      setNoDataPinnedMessage("");
      console.log("Fetching pinned topics...");
      const response = await axiosServices.get("/pin", {
        params: {
          project_id: projectId,
          email: userEmail,
        },
      });

      console.log("Fetched pinned topics response:", response.data);

      if (response.data.message === "No pinned topics here") {
        setNoDataPinnedMessage("No pinned topics available.");
        setPinnedTopics([]);
      } else {
        setPinnedTopics(response.data);
      }
    } catch (error) {
      console.error(
        "Error fetching pinned topics:",
        error.response?.data || error.message
      );
      setError(
        error.response?.data?.error ||
          "Failed to fetch pinned topics. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  }, [projectId, userEmail]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: "15px",
        "@media (max-width: 768px)": {
          padding: "10px",
          pb: 10,
        },
      }}
    >
      <Box
        sx={{
          ml: 2,
          "@media (max-width: 768px)": {
            ml: 0,
          },
        }}
      >
        <Breadcrumbs
          separator={
            <ArrowForwardIosIcon
              sx={{
                fontSize: 14,
                color: "black",
                "@media (max-width: 768px)": {
                  fontSize: 12,
                },
              }}
            />
          }
          aria-label="breadcrumb"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "@media (max-width: 768px)": {
                flexDirection: "row",
              },
            }}
          >
            <Box
              component="img"
              src={vector}
              alt="All Projects"
              sx={{
                width: 20,
                height: 20,
                marginRight: 1.5,
                "@media (max-width: 768px)": {
                  width: 16,
                  height: 16,
                },
              }}
            />
            <Link
              underline="hover"
              color="inherit"
              onClick={() => navigate(-1)}
              sx={{
                fontSize: "17px",
                cursor: "pointer",
                color: "#525252",
                textDecoration: "none",
                "@media (max-width: 768px)": {
                  fontSize: "15px",
                },
              }}
            >
              All Projects
            </Link>
          </Box>

          <Box
            sx={{
              fontSize: "17px",
              fontWeight: "500",
              color: "black",
              "@media (max-width: 768px)": {
                fontSize: "15px",
              },
            }}
          ></Box>
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          pb: 14,
          "@media (max-width: 1024px)": {
            flexDirection: "column",
            height: "auto",
            pb: 8,
          },
        }}
      >
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
            justifyContent: "space-between",
            scrollbarWidth: "none",
            "@media (max-width: 768px)": {
              flex: 1,
              padding: "10px",
            },
          }}
        >
          <ExecutiveSummary />
          <ChatBot
            project={project}
            messages={messages}
            setMessages={setMessages}
            fetchPinnedTopics={fetchPinnedTopics}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            p: 10,
            pt: 8.5,
            "@media (max-width: 1024px)": {
              p: 5,
              pt: 5,
            },
            "@media (max-width: 768px)": {
              p: 3,
              pt: 3,
            },
          }}
        >
          <Box>
            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                {/* <CircularProgress /> */}
              </Box>
            ) : noDataMessage ? (
              <Typography
                variant="h6"
                sx={{
                  color: "#525252",
                  fontSize: "18px",
                  "@media (max-width: 768px)": {
                    fontSize: "16px",
                  },
                }}
              >
                {noDataMessage}
              </Typography>
            ) : Object.keys(mermaidCode).length === 0 ? (
              <Typography sx={{ textAlign: "center", color: "#525252", mt: 4 }}>
                No Mermaid diagrams found.
              </Typography>
            ) : (
              Object.entries(mermaidCode).map(([title, { mermaid_code }]) => (
                <ReusableAccordion
                  key={title}
                  title={title}
                  onClick={() => handleAccordionClick(title, mermaid_code)}
                >
                  <Typography>
                    Click to view the Mermaid Diagram for {title}
                  </Typography>
                </ReusableAccordion>
              ))
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              mt: 3,
              "@media (max-width: 768px)": {
                mt: 2,
              },
            }}
          >
            <img
              src={PushPinIconImg}
              alt=""
              style={{
                marginRight: "8px",
                marginTop: "5px",
              }}
            />

            <Typography
              variant="h6"
              sx={{
                color: "#525252",
                fontSize: "18px",
                fontWeight: "bold",
                "@media (max-width: 768px)": {
                  fontSize: "16px",
                },
              }}
            >
              Pinned Topics
            </Typography>
          </Box>

          <PinnedTopics
            projectId={projectId}
            userEmail={userEmail}
            fetchPinnedTopics={fetchPinnedTopics}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;
