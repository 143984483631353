import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './layout';
import Projects from './pages/Projects';
import Support from './pages/Support';
import { useAuth } from './context/AuthContext';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';

import MermaidPage from './pages/Mermaid';
import Chat from './pages/Chat/Chat';

function PrivateRoute({ element, isAuthenticated }) {
  console.log('PrivateRoute isAuthenticated:', isAuthenticated);
  return isAuthenticated ? element : <Navigate to='/login' />;
}

function PublicRoute({ element, isAuthenticated }) {
  return isAuthenticated ? <Navigate to='/projects' /> : element;
}

function AppRoutes() {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <Routes>
        <Route path='/' element={<PublicRoute element={<Login />} isAuthenticated={isAuthenticated} />} />
        <Route path='/login' element={<PublicRoute element={<Login />} isAuthenticated={isAuthenticated} />} />
        <Route path='/register' element={<PublicRoute element={<Register />} isAuthenticated={isAuthenticated} />} />
        <Route element={<Layout />}>
          <Route path='/projects' element={<PrivateRoute element={<Projects />} isAuthenticated={isAuthenticated} />} />
          <Route path='/support' element={<PrivateRoute element={<Support />} isAuthenticated={isAuthenticated} />} />
          {/* <Route
            path="/chat"
            element={
              <PrivateRoute
                element={<Chat />}
                isAuthenticated={isAuthenticated}
              />
            }
          /> */}
          <Route path='/chat' element={<PrivateRoute element={<Chat />} isAuthenticated={isAuthenticated} />} />
          <Route path='/mermaid/:title' element={<PrivateRoute element={<MermaidPage />} isAuthenticated={isAuthenticated} />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRoutes;
