import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
  },
  palette: {
    primary: {
      main: "#58A942",
      contrastText: "#FFFFFF",
      light: "#feb47b",
    },
    secondary: {
      main: "#FFC107",
      light: "#FFE082",
    },
    background: {
      dark: "#BDBDBD",
      light: "#F7F7F7",
    },
  },
});

export default theme;
