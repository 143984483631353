import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import axiosServices from "../../utils/axiosServices";
import logo from "../../assets/images/logo.svg";
import PushPinIcon from "@mui/icons-material/PushPin";
import { ThreeDots } from "react-loader-spinner";
import { Send } from "@mui/icons-material";

const ChatBot = ({ project, messages, setMessages, fetchPinnedTopics }) => {
  const { user } = useAuth();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [topics, setTopics] = useState([]); // For existing topics
  const [newTopic, setNewTopic] = useState(""); // For creating a new topic
  const [openDialog, setOpenDialog] = useState(false);
  const [showCreateField, setShowCreateField] = useState(false); // Controls dialog visibility
  const chatContainerRef = useRef(null);
  const [selectedMessageContent] = useState(""); // For storing the selected message content

  const getChatHistory = useCallback(async () => {
    if (!user?.user?.email || !project?.project_id) return;
    setIsLoading(true);
    try {
      const response = await axiosServices.get(
        `/messages?email=${user.user.email}&projectId=${project.project_id}`
      );
      setMessages(response?.data || []);
    } catch (error) {
      console.error("Error fetching chat history:", error);
    } finally {
      setIsLoading(false);
    }
  }, [user?.user?.email, project?.project_id, setMessages]);

  const fetchTopics = useCallback(async () => {
    try {
      const response = await axiosServices.get(
        `/pin?project_id=${project?.project_id}&email=${user?.user?.email}`
      );
      const newTopics = Array.isArray(response?.data) ? response?.data : [];
      setTopics(newTopics);
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  }, [project?.project_id, user?.user?.email]);

  const handlePinMessage = async (topicName, msgContent) => {
    if (!project?.project_id) {
      console.error("Error: Project ID is undefined.");
      return;
    }

    try {
      const response = await axiosServices.post(
        `/pin?project_id=${project.project_id}&email=${
          user?.user?.email || ""
        }`,
        {
          topic_name: topicName || "Default Topic",
          pin_content: msgContent,
        }
      );
      console.log("Pinning response:", response);
      setOpenDialog(false);
      console.log("Calling fetchPinnedTopics after pinning");
      fetchPinnedTopics();
      window.location.reload();
    } catch (error) {
      console.error("Error pinning message:", error);
    }
  };

  useEffect(() => {
    if (project) {
      getChatHistory();
      fetchTopics();
    }
  }, [project, getChatHistory, fetchTopics]);

  useEffect(() => {
    chatContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    const userMessage = message.trim();
    setMessage("");
    setIsMessageLoading(true);

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: userMessage },
    ]);

    try {
      const response = await axiosServices.post(`/messages`, {
        userQuestion: userMessage,
        projectId: project.project_id,
        email: user?.user?.email,
        // Ensure no topic_name is included here unless necessary
      });

      const assistantResponse = response?.data?.result || "No response";

      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", content: assistantResponse },
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "error", content: "Failed to send message" },
      ]);
    } finally {
      setIsMessageLoading(false);
    }
  };

  const handleCreateNewTopic = () => {
    setShowCreateField(true); // Show the TextField and button when "Create New Topic" is clicked
  };

  const handleSaveNewTopic = () => {
    if (newTopic.trim()) {
      handlePinMessage(newTopic, selectedMessageContent);
      setNewTopic(""); // Clear the input field after saving
      setOpenDialog(false); // Close the dialog after saving
    }
  };

  // const handleOpenDialog = (msgContent) => {
  //   setSelectedMessageContent(msgContent); // Store the message content
  //   setOpenDialog(true); // Open the dialog
  // };

  const formatAssistantResponse = (response) => {
    // Assuming each topic starts with a number followed by a dot (e.g., "1. Topic:")
    const lines = response.split("\n");
    return lines.map((line, idx) => {
      // Check if the line starts with a topic number followed by a dot (e.g., "1." or "2.")
      if (line.match(/^\d+\./)) {
        return (
          <Typography
            key={idx}
            variant="body2"
            sx={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            {line}
          </Typography>
        );
      } else {
        // For other content, render normally
        return (
          <Typography key={idx} variant="body2" sx={{ marginBottom: "8px" }}>
            {line}
          </Typography>
        );
      }
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          flex: 1,
          overflowY: isMessageLoading ? "hidden" : "auto",
          mb: 5,
          "&::-webkit-scrollbar": {
            display: isMessageLoading ? "none" : "block",
          },
          scrollbarWidth: isMessageLoading ? "none" : "auto",
        }}
      >
        {messages.length > 0
          ? isLoading
            ? ""
            : messages.map((msg, idx) => (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: msg.role === "user" ? "flex-end" : "flex-start", // Keeps sent messages on the right and received on the left
                  }}
                >
                  {/* Message Content */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent:
                        msg.role === "user" ? "flex-end" : "flex-start",
                      pl: "44px",
                    }}
                  >
                    {msg.role === "assistant" && (
                      <Box
                        sx={{
                          display: "flex",
                          pt: "8px",
                        }}
                      >
                        <Avatar
                          src={logo}
                          alt="Assistant Avatar"
                          sx={{
                            width: 35,
                            height: 35,
                            border: "2px solid #D9D9D9",
                            borderRadius: "50%",
                          }}
                        />
                      </Box>
                    )}
                    <Box
                      sx={{
                        backgroundColor: msg.role === "user" ? "#f0f0f0" : "",
                        color: "#000",
                        borderRadius: "20px",
                        padding: "10px 10px",
                        mt: "5px",
                        maxWidth: "fit-content",
                        alignSelf:
                          msg.role === "user" ? "flex-end" : "flex-start",
                        boxShadow:
                          msg.role === "user"
                            ? "0px 2px 4px rgba(0, 0, 0, 0.1)"
                            : "",
                      }}
                    >
                      {msg.role === "assistant"
                        ? formatAssistantResponse(msg.content)
                        : msg.content}{" "}
                    </Box>
                  </Box>

                  {msg.role === "assistant" && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: "-5px",
                        marginLeft: "100px",
                      }}
                    >
                      <IconButton
                        sx={{
                          color: "#525252",
                        }}
                        // onClick={() => handleOpenDialog(msg.content)}
                        onClick={() => {
                          // Find the user's message corresponding to this assistant response
                          const userMessage = messages.find(
                            (m, index) =>
                              m.role === "user" &&
                              messages[index + 1]?.role === "assistant" &&
                              messages[index + 1].content === msg.content
                          )?.content;

                          if (userMessage) {
                            // Pass userMessage as topic_name and assistant's msg.content as pin_content
                            handlePinMessage(userMessage, msg.content);
                          }
                        }}
                      >
                        {" "}
                        <PushPinIcon
                          fontSize="small"
                          sx={{ transform: "rotate(45deg)" }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              ))
          : ""}
        {isMessageLoading && (
          <Box
            sx={{
              display: "flex",
              pl: "80px",
              height: "1%",
            }}
          >
            <ThreeDots
              height="40"
              width="40"
              radius="9"
              color="#525252"
              ariaLabel="three-dots-loading"
              visible={true}
            />
          </Box>
        )}
        <div ref={chatContainerRef} />
      </Box>

      <Box
        sx={{
          position: "fixed",
          width: "60%", // Default width for larger screens
          bottom: 0,
          zIndex: 1,
          p: 1,
          pl: 5.5,
          pb: 8,
          background: "white",
          "@media (max-width: 1024px)": {
            width: "100%", // Full width for medium screens
            pl: 3, // Adjust padding for smaller screens
            pr: 3,
          },
          "@media (max-width: 768px)": {
            width: "100%", // Full width for mobile
            pl: 2, // Reduced padding for mobile
            pr: 2,
          },
        }}
      >
        <TextField
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          placeholder="Ask Ai a question or make a request..."
          disabled={isMessageLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSendMessage}
                  disabled={isMessageLoading}
                  color="primary"
                >
                  <Send sx={{ transform: "rotate(-45deg)", mr: "10px" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              background: "white",
              borderRadius: "10px",
              boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #D9D9D9",
                pb: "10px",
              },
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #D9D9D9",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: "1px solid #D9D9D9",
              },
            "@media (max-width: 1024px)": {
              width: "100%",
            },
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        />
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sx={{
          "& .MuiDialog-paper": {
            height: "35vh",
            width: "450px", // Increase the width of the dialog
            maxWidth: "50vw", // Make the dialog responsive (80% of viewport width)
          },
        }}
      >
        <DialogTitle>Pinning Response</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", gap: 4 }}>
            {/* Left Section: Existing Topics */}
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                paddingRight: 2,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Topics
              </Typography>
              <List>
                {topics.map((topic, idx) => (
                  <ListItem
                    button
                    key={idx}
                    onClick={() => {
                      setNewTopic(topic.name); // Set the selected topic
                      setOpenDialog(false); // Close dialog after selection
                    }}
                  >
                    <ListItemText primary={topic.name} />
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* Right Section: Create New Topic */}
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
              {/* <Typography variant="h6" gutterBottom>
                {showCreateField
                  ? "Create New Topic"
                  : "Click to Create New Topic"}
              </Typography> */}

              {showCreateField ? (
                <Box>
                  <TextField
                    label="Topic Name"
                    value={newTopic}
                    onChange={(e) => setNewTopic(e.target.value)}
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    onClick={handleSaveNewTopic}
                    sx={{ mt: 2 }}
                  >
                    Create and Pin
                  </Button>
                </Box>
              ) : (
                <Typography color="primary" onClick={handleCreateNewTopic}>
                  Create New Topic
                </Typography>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChatBot;
