import * as Yup from "yup";

const otpValidationSchema = Yup.object({
  otp: Yup.array()
    .of(Yup.string().required("6  digit is required"))
    .test(
      "is-complete",
      "OTP must be exactly 6 digits and each digit is required.",
      (value) => Array.isArray(value) && value.length === 6
    ),
});

export default otpValidationSchema;
