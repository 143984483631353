import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MermaidDiagram } from "@lightenna/react-mermaid-diagram";
import { Box, Typography, Breadcrumbs, Link } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const MermaidPage = () => {
  const { title } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const mermaidCode = location.state?.mermaidCode;
  if (!mermaidCode) {
    return (
      <Box>
        <Typography variant="h6">Error</Typography>
        <Typography>No Mermaid code provided!</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: "20px" }}>
      <Breadcrumbs
        separator={
          <ArrowBackIosNewOutlinedIcon
            sx={{
              fontSize: 14,
              color: "black",
              "@media (max-width: 768px)": {
                fontSize: 12,
              },
            }}
          />
        }
        aria-label="breadcrumb"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <ArrowBackIosNewOutlinedIcon
            sx={{
              fontSize: 14,
              color: "black",
              marginRight: "8px", // Add some space between the icon and text
              "@media (max-width: 768px)": {
                fontSize: 12,
              },
            }}
          />
          <Link
            underline="hover"
            color="inherit"
            onClick={() => navigate(-1)}
            sx={{
              fontSize: "17px",
              cursor: "pointer",
              color: "#525252",
              textDecoration: "none",
              "@media (max-width: 768px)": {
                fontSize: "15px",
              },
            }}
          >
            Back
          </Link>
        </Box>
      </Breadcrumbs>
      <Typography variant="h4" sx={{ mb: 4, mt: 8 }}>
        {title}
      </Typography>
      <Box sx={{ padding: "20px" }}>
        <MermaidDiagram>{mermaidCode}</MermaidDiagram>
      </Box>
    </Box>
  );
};

export default MermaidPage;
