import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import axiosServices from "../../utils/axiosServices";
import { useAuth } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import ReusableAccordion from "../../components/ReusableAccordion/ReusableAccordion";

const ExecutiveSummary = () => {
  const location = useLocation();
  const { user } = useAuth();
  const projectName = location.state?.projectName;
  const project = location.state?.project;
  const projectId = project?.project_id;
  const userEmail = user?.user?.email;
  const [executiveSummary, setExecutiveSummary] = useState({});
  const [, setNoDataSummaryMessage] = useState("");
  const [, setIsLoading] = useState(false);

  const getSummary = useCallback(async () => {
    try {
      setIsLoading(true);
      setNoDataSummaryMessage("");
      const response = await axiosServices.get(`/summary`, {
        params: {
          project_id: projectId,
          email: userEmail,
        },
      });
      if (!response.data) {
        setExecutiveSummary({});
        setNoDataSummaryMessage(" ");
        return;
      }
      const processedData = JSON.parse(response.data);

      if (processedData) {
        setExecutiveSummary(processedData);
      } else {
        setExecutiveSummary({});
        setNoDataSummaryMessage("Error: Mermaid code not available.");
      }
    } catch (error) {
      console.error("Error fetching mermaid code:", error);
      setExecutiveSummary({});
      setNoDataSummaryMessage("Error: Failed to fetch mermaid code.");
    } finally {
      setIsLoading(false);
    }
  }, [projectId, userEmail]);

  useEffect(() => {
    getSummary(); // Fetch pinned topics whenever the component mounts or when dependencies change
  }, [getSummary]);

  return (
    <div>
      <Box sx={{ position: "relative", ml: 2 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "#525252",
            fontSize: "25px",
            fontWeight: "bold",
            pb: 1,
            pl: "32px",
            pt: "20px",
          }}
        >
          {projectName ? `${projectName}` : "Project Details"}
        </Typography>

        <Box
          sx={{
            position: "absolute",
            left: 10,
            top: "70px",
            height: `calc(100% - 85px)`,
            width: "1px",
            backgroundColor: "#58A94233",
          }}
        />

        {Object.entries(executiveSummary).map(([title, description], index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              position: "relative",
              mb: 0.5,
            }}
          >
            <Box
              sx={{
                position: "relative",
                marginBottom: "10px",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  left: "7px",
                  top: "15px",
                  width: "5.5px",
                  height: "5.5px",
                  backgroundColor: "#58A942",
                  borderRadius: "50%",
                  zIndex: 1,
                },
              }}
            />

            <Box sx={{ ml: 4, flex: 1 }}>
              <ReusableAccordion title={title}>
                <Box component="ul" sx={{ p: 0, listStyleType: "none" }}>
                  {description.split("\n\n").map((point, idx) => (
                    <Typography key={idx} variant="body1">
                      {point}
                    </Typography>
                  ))}
                </Box>
              </ReusableAccordion>
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default ExecutiveSummary;
