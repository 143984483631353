import { Add, CalendarToday } from "@mui/icons-material";
import {
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  Box,
  Snackbar,
  SnackbarContent,
  Chip,
  CircularProgress,
  IconButton,
} from "@mui/material";
import BasicModal from "../components/modal";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import AddProject from "./AddProject";
import axiosServices from "../utils/axiosServices";
import { useAuth } from "../context/AuthContext";
import Mermaid from "./Mermaid";
//import { IconButton } from "@mui/material";
import { useConfirmationPopup } from "../components/openconfrmationmodel";
import Header from "../components/Header/Header";
// import StarBorderIcon from "../assets/images/Star.svg";
// import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
// import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Background from "../assets/images/Background.svg";
import moment from "moment-timezone";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const projectStatusMap = {
  "Stage 1 Completed": "Ready",
  "Error: Took more than 7 hrs to run": "Error",
  " Summary being generated": "In Progress",
};

const projectStatusColorMap = {
  Ready: {
    backgroundColor: "#BBDEB2",
  },
  Error: {
    backgroundColor: "#F9C6C2",
  },
  "In Progress": {
    backgroundColor: "#F6DEA6",
  },
};

const getProjectStatus = (status) => {
  const processedStatus = projectStatusMap[status] || status;
  if (
    processedStatus?.toLowerCase().includes("error") ||
    processedStatus?.toLowerCase().includes("no summaries generated")
  ) {
    return "Error";
  }
  return processedStatus;
};

function Loader() {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="rgba(0, 0, 0, 0.5)"
      zIndex="9999"
    >
      <CircularProgress />
    </Box>
  );
}

function Projects() {
  // eslint-disable-next-line no-unused-vars
  const { openConfirmationPopup, ConfirmationModal } = useConfirmationPopup();
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [modalContent, setModalContent] = useState();
  const [modalHeader, setModalHeader] = useState();
  const [modalWidth, setModalWidth] = useState();
  const [modalHeight, setModalHeight] = useState();
  const formatDate = (utcTimestamp) => {
    const date = moment.utc(utcTimestamp);

    const istDate = date.tz("Asia/Kolkata");

    const formattedDate = istDate.format("Do MMMM YYYY");
    const formattedTime = istDate.format("h:mm A");

    return { time: formattedTime, formattedDate };
  };

  const getAllProjects = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosServices.get(
        `/projects?email=${user?.user?.email}`
      );
      if (response.data && response.data.length) {
        setProjects(response.data);
      } else {
        setProjects([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [user?.user?.email]);

  useEffect(() => {
    getAllProjects();
  }, [getAllProjects]);

  const openTheModal = (modalPurpose, projectId = null) => {
    if (!projectId && modalPurpose === "mermaid_diagram") {
      setSnackbarMessage("Unable to load Mermaid diagram. Invalid project.");
      setSnackbarOpen(true);
      return;
    }

    if (modalPurpose === "addProject") {
      setModalHeader("Create a new Project");
      setModalWidth("400px");
      setModalHeight("auto");
      setModalContent(
        <AddProject
          onSuccess={handleAddProjectSuccess}
          onFailure={handleAddProjectFailure}
        />
      );
    } else {
      setModalHeader("Mermaid Diagram");
      setModalWidth("100%");
      setModalHeight("100%");
      setModalContent(<Mermaid projectId={projectId} />);
    }
    setIsModalOpen(true);
  };

  const handleAddProjectSuccess = () => {
    setSnackbarMessage("Project created successfully");
    setSnackbarOpen(true);
    setIsModalOpen(false);
    getAllProjects();
  };

  const handleAddProjectFailure = () => {
    setSnackbarMessage("Project creation failed");
    setSnackbarOpen(true);
    setIsModalOpen(false);
    getAllProjects();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleChat = (project) => {
    if (getProjectStatus(project.status) === "Error") {
      return; // Do nothing if the status is "Error"
    }
    navigate("/chat", {
      state: {
        project,
        projectName: project.project_name,
        projectId: project.project_id,
      },
    });
  };

  const handleIconClick = (projectId) => {
    openConfirmationPopup({
      title: "Delete Project",
      message: `Are you sure you want to delete this project? This action cannot be undone.`,
      confirmButtonLabel: "Delete",
      cancelButtonLabel: "Cancel",
      onConfirm: () => handleDelete(projectId),
      onCancel: () => console.log("Delete canceled"),
    });
  };

  // const handleSummary = async (projectId) => {
  //   setIsLoading(true);
  //   try {
  //     const input = { email: user?.user?.email, projectId: projectId };
  //     const response = await axiosServices.post(`/projects/summary`, input);
  //     if (response.status === 200) {
  //       setSnackbarMessage(response.data?.message);
  //       setSnackbarOpen(true);
  //     }
  //   } catch (error) {
  //     console.error("Error sending email:", error);
  //     setSnackbarMessage("Failed to send email. Please try again.");
  //     setSnackbarOpen(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleDelete = async (projectId) => {
    const project = projects.find((proj) => proj.project_id === projectId);
    const projectName = project ? project.project_name : "Project";

    try {
      setIsLoading(true);
      const response = await axiosServices.post(`/projects/delete`, {
        projectId,
        email: user?.user?.email,
      });

      if (response.status === 200) {
        setSnackbarMessage(`Project "${projectName}" has been deleted.`);
        setSnackbarOpen(true);

        // Update state to remove the deleted project
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.project_id !== projectId)
        );
      } else {
        throw new Error("Failed to delete the project.");
      }
    } catch (error) {
      console.error("Delete failed:", error);
      setSnackbarMessage("Failed to delete the project.");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "contain",
          backgroundRepeat: "repeat",
          minHeight: "100vh",
          padding: 0,
          m: 0,
        }}
      >
        <Header />

        <Container maxWidth={false} disableGutters sx={{ m: 0, p: 3, py: 1 }}>
          {isLoading ? (
            <Loader />
          ) : projects.length === 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-start"
              height="80vh"
              textAlign="center"
            >
              <Box
                sx={{
                  p: 4,
                  borderRadius: 2,
                  boxShadow: 2,
                  bgcolor: "background.paper",
                  maxWidth: 500,
                  width: "100%",
                }}
              >
                <Typography variant="h5" sx={{ mb: 3, color: "text.primary" }}>
                  Start harmonizing your projects!
                </Typography>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => openTheModal("addProject")}
                  fullWidth
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    py: 1.5,
                  }}
                >
                  Create a New Project
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                mb={8}
                justifyContent="space-between"
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "#525252",
                    fontSize: "22px",
                    fontWeight: "bold",
                  }}
                >
                  My Projects
                </Typography>

                <Box display="flex" alignItems="center" gap={1} mr={2}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "10px", textTransform: "none" }}
                    startIcon={<Add />}
                    onClick={() => openTheModal("addProject")}
                  >
                    Create New Project
                  </Button>

                  {/* <MenuOutlinedIcon
                    sx={{
                      color: "#525252",
                      fontSize: "28px",
                      cursor: "pointer",
                    }}
                    onClick={() => console.log("Menu icon clicked")}
                  /> */}
                </Box>
              </Box>

              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))"
                gap={2}
                sx={{
                  padding: 0,
                  "@media (max-width: 600px)": {
                    gap: 0, // Decrease gap on smaller screens (adjust as needed)
                  },
                }}
              >
                {projects
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((project) => {
                    const projectStatus = getProjectStatus(project.status);
                    const isDisabled =
                      projectStatus === "In Progress" ||
                      projectStatus === "Error";

                    // const isInProgress = projectStatus
                    //   .toLowerCase()
                    //   .includes("in progress");
                    return (
                      <Card
                        key={project.project_id}
                        sx={{
                          maxWidth: 330,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                          padding: 2,
                          pb: 0.3,
                          borderRadius: "10px",
                          position: "relative",
                          cursor: "pointer",
                        }}
                        // onClick={() => handleChat(project)}
                        onClick={() => !isDisabled && handleChat(project)}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: 4,
                            right: 8,
                            display: "flex",
                          }}
                        >
                          {projectStatus === "In Progress" ? (
                            ""
                          ) : (
                            <>
                              {" "}
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent the card click from firing
                                  handleIconClick(project.project_id); // Call the delete handler with the project id
                                }}
                                sx={{ color: "#B8B8B8", fontSize: "25px" }}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            </>
                          )}
                        </Box>
                        <Box
                          sx={{
                            position: "absolute",
                            top: 4,
                            right: 8,
                            display: "flex",
                          }}
                        >
                          {/* <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              console.log("Starred icon clicked");
                            }}
                            sx={{ color: "grey", fontSize: "25px" }}
                          >
                            <img
                              src={StarBorderIcon}
                              alt=""
                              style={{
                                width: "15px",
                                height: "15px",
                              }}
                            />
                          </IconButton> */}

                          {/* <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleIconClick(project.project_id);
                            }}
                          >
                            <MoreHorizOutlinedIcon
                              style={{ cursor: "pointer" }}
                              sx={{ color: "#939393" }}
                            />
                            {ConfirmationModal}
                          </IconButton> */}
                        </Box>
                        <Box
                          display="flex"
                          sx={{
                            marginBottom: 1,
                            mr: 2,
                          }}
                        >
                          <Chip
                            label={projectStatus}
                            variant="outlined"
                            size="small"
                            sx={{
                              fontSize: "0.7rem",
                              backgroundColor:
                                projectStatusColorMap[projectStatus]
                                  ?.backgroundColor,
                              border: "none",
                              p: 0.5,
                            }}
                          />
                        </Box>

                        <CardContent sx={{ flex: 1, paddingTop: 1, pl: 1 }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "1rem",
                              color: "#525252",
                              marginBottom: "8px",
                            }}
                          >
                            {project.project_name}
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{
                              color: "#525252",
                              fontSize: "0.9rem",
                            }}
                          >
                            {project.description}
                          </Typography>
                        </CardContent>

                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                          sx={{
                            paddingTop: 1,
                            paddingBottom: 1,
                            borderTop: "0.5px solid #CBD5E1",
                            marginTop: "auto",
                            width: "100%",
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <AccessTimeOutlinedIcon
                              sx={{
                                color: "#525252",
                                mr: 0.5,
                                fontSize: "0.9rem",
                              }}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                color: "#525252",
                                fontSize: "0.7rem",
                              }}
                            >
                              {formatDate(project.created_at).time}
                            </Typography>
                          </Box>

                          <Box
                            display="flex"
                            alignItems="center"
                            sx={{ ml: 2 }}
                          >
                            <CalendarToday
                              sx={{
                                color: "#525252",
                                mr: 0.5,
                                fontSize: "0.8rem",
                              }}
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                color: "#525252",
                                fontSize: "0.7rem",
                              }}
                            >
                              {formatDate(project.created_at).formattedDate}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    );
                  })}
              </Box>
            </>
          )}

          <BasicModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            headerText={modalHeader}
            content={modalContent}
            width={modalWidth}
            height={modalHeight}
          />
          {ConfirmationModal}

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={snackbarOpen}
            autoHideDuration={1500}
            onClose={handleSnackbarClose}
          >
            <SnackbarContent
              style={{
                backgroundColor: "teal",
              }}
              message={<span id="message-snackbar">{snackbarMessage}</span>}
            />
          </Snackbar>
        </Container>
      </Box>
    </>
  );
}

export default Projects;
