import * as Yup from "yup";
import ERROR_MESSAGES from "../constants/errorMessages";

const forgotPasswordValidationSchema = {
  email: Yup.string()
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .required(ERROR_MESSAGES.REQUIRED),

  otp: Yup.array()
    .of(
      Yup.string()
        .length(1, "Each OTP digit must be 1 character")
        .matches(/^\d$/, "Each OTP digit must be a number")
    )
    .test(
      "length",
      "OTP must be 6 digits",
      (value) => Array.isArray(value) && value.length === 6
    )
    .required(ERROR_MESSAGES.REQUIRED),

  newPassword: Yup.string()
    .min(8, ERROR_MESSAGES.PASSWORD_WEAK)
    .matches(/[A-Z]/, ERROR_MESSAGES.PASSWORD_WEAK)
    .matches(/[a-z]/, ERROR_MESSAGES.PASSWORD_WEAK)
    .matches(/[0-9]/, ERROR_MESSAGES.PASSWORD_WEAK)
    .matches(/[!@#$%^&*]/, ERROR_MESSAGES.PASSWORD_WEAK)
    .required(ERROR_MESSAGES.REQUIRED),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], ERROR_MESSAGES.PASSWORD_MISMATCH)
    .required(ERROR_MESSAGES.REQUIRED),
};

export default forgotPasswordValidationSchema;
